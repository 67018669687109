import asbestosFacilityService from "@/views/asbestosFacility/asbestosFacilityService";
import { apiService, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiHosts } from "@/api/constants/apiHosts";

import { AsbestosFacility, AsbestosFacilityNew, AsbestosFacilityQuery } from "./asbestosFacility";
import type { AxiosResponse } from "axios";
import { LE } from "@/constants/enums";

const URL = "asbestos-facilities";
const ENTITY_NAME = "Asbestos Facility";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_AQMS_API_BASE_URL,
};
const api = apiService<AsbestosFacility>(apiHosts.AQMS, axiosParams);

const getAsbestosFacilities = (params: AsbestosFacilityQuery, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getAsbestosFacilityById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewAsbestosFacility = async (ids: AsbestosFacilityIds) => {
  const defaultApp = {
    data: {
      ...asbestosFacilityService.getInitialData(),
      ...ids,
      stateMurn: LE.State.NV,
    },
  } as AxiosResponse<AsbestosFacilityNew, any>;

  return defaultApp;
};

type AsbestosFacilityIds = {
  id?: string | number;
};
const getAsbestosFacilityByIdOrNew = (ids: AsbestosFacilityIds, config: PagingApiAndNotifierConfig = {}) => {
  if (!ids.id) {
    return getNewAsbestosFacility(ids);
  } else {
    return getAsbestosFacilityById(ids.id, config) as Promise<AxiosResponse<AsbestosFacilityNew, any>>;
  }
};

const createAsbestosFacility = (asbestosFacility: AsbestosFacility, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, asbestosFacility, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateAsbestosFacility = (asbestosFacility: AsbestosFacility, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + asbestosFacility.id, asbestosFacility, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {AsbestosFacility} asbestosFacility This cannot be a 'ref' to a asbestosFacility
 */
const saveAsbestosFacility = (asbestosFacility: AsbestosFacility, config = {}) => {
  if (asbestosFacility.id) {
    return updateAsbestosFacility(asbestosFacility, config);
  } else {
    return createAsbestosFacility(asbestosFacility, config);
  }
};

const deleteAsbestosFacilityById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteAsbestosFacilityById,
  getAsbestosFacilities,
  getAsbestosFacilityById,
  getNewAsbestosFacility,
  getAsbestosFacilityByIdOrNew,
  createAsbestosFacility,
  updateAsbestosFacility,
  saveAsbestosFacility,
};
