import { EntityAudit } from "@modernary/mui/components/audit";
import { RouterView, type RouterOptions } from "vue-router";

// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  stationarySources: "StationarySources",
  stationarySourceList: "StationarySourceList",
  stationarySourceNew: "StationarySourceNew",
  stationarySourceBase: "StationarySourceBase",
  stationarySourceView: "StationarySourceView",
  stationarySourceEdit: "StationarySourceEdit",
  stationarySourceUsers: "StationarySourceUsers",
  stationarySourceUserNew: "StationarySourceUserNew",
  stationarySourceAudit: "StationarySourceAudit",
};

export { names as stationarySourceRouteNames };

const stationarySourceRoutes: RouterOptions["routes"] = [
  {
    path: "/stationarySource",
    name: names.stationarySources,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.stationarySourceList },
    children: [
      {
        path: "list",
        name: names.stationarySourceList,
        meta: { title: "Stationary Sources" },
        component: () => import("@/views/stationarySource/StationarySourceList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/stationarySource/StationarySourceBase.vue"),
        redirect: { name: names.stationarySourceNew },
        props: true,
        children: [
          {
            path: "",
            name: names.stationarySourceNew,
            meta: { title: "New Stationary Source", isNew: true },
            component: () => import("@/views/stationarySource/StationarySourceEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.stationarySourceBase,
        component: () => import("@/views/stationarySource/StationarySourceBase.vue"),
        redirect: { name: names.stationarySourceView },
        children: [
          {
            path: "view",
            name: names.stationarySourceView,
            meta: { title: "Stationary Source View" },
            component: () => import("@/views/stationarySource/StationarySourceView.vue"),
          },
          {
            path: "edit",
            name: names.stationarySourceEdit,
            meta: { title: "Stationary Source Edit", isForm: true },
            component: () => import("@/views/stationarySource/StationarySourceEdit.vue"),
          },
          {
            path: "users",
            name: names.stationarySourceUsers,
            meta: { title: "Stationary Source Users" },
            component: () => import("@/views/stationarySource/StationarySourceUsers.vue"),
          },
          {
            path: "users/new",
            name: names.stationarySourceUserNew,
            meta: { title: "Add Stationary Source User", isForm: true },
            component: () => import("@/views/stationarySource/StationarySourceUser.vue"),
          },
          {
            path: "activity",
            name: names.stationarySourceAudit,
            meta: { title: "Stationary Source Audit Activity" },
            component: {
              render: () => <EntityAudit entityType="StationarySource" />,
            },
          },
        ],
      },
    ],
  },
];
export default stationarySourceRoutes;
