import Home from "./views/Home.vue";

import { NotAuthorized, NotFound } from "@modernary/mui/components/common";
import {
  default as asbestosFacilityRoutes,
  asbestosFacilityRouteNames,
} from "./views/asbestosFacility/asbestosFacilityRoutes";
import {
  default as constructionProjectRoutes,
  constructionProjectRouteNames,
} from "./views/constructionProject/constructionProjectRoutes";
import { default as tempRoutes, tempRouteNames } from "./views/temp/tempRoutes";
import {
  default as stationarySourceRoutes,
  stationarySourceRouteNames,
} from "./views/stationarySource/stationarySourceRoutes";
import {
  default as vacantLandParcelRoutes,
  vacantLandParcelRouteNames,
} from "./views/vacantLandParcel/vacantLandParcelRoutes";
import type { RouterOptions } from "vue-router";
// Troubleshoot your routes using this - https://paths.esm.dev

export const routeNames = {
  home: "Home",
  notAuthorized: "NotAuthorized",
  ...vacantLandParcelRouteNames,
  ...asbestosFacilityRouteNames,
  ...constructionProjectRouteNames,
  ...stationarySourceRouteNames,
  ...tempRouteNames,
};

export const routes: RouterOptions["routes"] = [
  { path: "/", component: Home, name: routeNames.home, meta: { title: "Home" } },
  {
    path: "/unauthorized",
    component: NotAuthorized,
    name: routeNames.notAuthorized,
    meta: { title: "Not Authorized" },
  },
  ...vacantLandParcelRoutes,
  ...asbestosFacilityRoutes,
  ...constructionProjectRoutes,
  ...tempRoutes,
  ...stationarySourceRoutes,
  { path: "/:path(.*)", component: NotFound },
];
