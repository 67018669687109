import {
  addressFromFormkit,
  addressToFormkit,
  plainMurnAddressFromFormkit,
  plainMurnAddressToFormkit,
} from "@modernary/mui/components/fk";

import { AsbestosFacility, AsbestosFacilityNew } from "@/views/asbestosFacility/asbestosFacility";
import { getLookupDescription } from "@modernary/mui/components/lookup";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: AsbestosFacility) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as AsbestosFacility; //TODO: ts
  return addAuthRequestFields(result);
}

/** Be sure to await this when using it. */
async function responseAdapter(response: { data: AsbestosFacility }) {
  if (response.data.stateMurn) {
    let state = await getLookupDescription(response.data.stateMurn);
    response.data.stateMurnValue = state;
  }
  if (response.data.countryMurn) {
    let country = await getLookupDescription(response.data.countryMurn);
    response.data.countryMurnValue = country;
  }
  addAuthRequestFields(response.data);

  return response.data;
}
function requestAdapter(data: AsbestosFacility) {
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit<T extends AsbestosFacility | AsbestosFacilityNew>(data: T) {
  plainMurnAddressToFormkit(data);
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: AsbestosFacility) {
  plainMurnAddressFromFormkit(data as AsbestosFacility);
}
