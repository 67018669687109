import stationarySourceService from "@/views/stationarySource/stationarySourceService";
import { apiService, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiHosts } from "@/api/constants/apiHosts";

import { StationarySource, StationarySourceNew, StationarySourceQuery } from "./stationarySource";
import type { AxiosResponse } from "axios";
import { LE } from "@/constants/enums";

const URL = "stationary-sources";
const ENTITY_NAME = "Stationary Source";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_AQMS_API_BASE_URL,
};
const api = apiService<StationarySource>(apiHosts.AQMS, axiosParams);

const getStationarySources = (params: StationarySourceQuery, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getStationarySourceById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewStationarySource = async (ids: StationarySourceIds) => {
  const defaultApp = {
    data: {
      ...stationarySourceService.getInitialData(),
      ...ids,
      stateMurn: LE.State.NV,
    },
  } as AxiosResponse<StationarySourceNew, any>;

  return defaultApp;
};

type StationarySourceIds = {
  id?: string | number;
};
const getStationarySourceByIdOrNew = (ids: StationarySourceIds, config: PagingApiAndNotifierConfig = {}) => {
  if (!ids.id) {
    return getNewStationarySource(ids);
  } else {
    return getStationarySourceById(ids.id, config) as Promise<AxiosResponse<StationarySourceNew, any>>;
  }
};

const createStationarySource = (stationarySource: StationarySource, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, stationarySource, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateStationarySource = (stationarySource: StationarySource, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + stationarySource.id, stationarySource, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {StationarySource} stationarySource This cannot be a 'ref' to a stationarySource
 */
const saveStationarySource = (stationarySource: StationarySource, config = {}) => {
  if (stationarySource.id) {
    return updateStationarySource(stationarySource, config);
  } else {
    return createStationarySource(stationarySource, config);
  }
};

const deleteStationarySourceById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteStationarySourceById,
  getStationarySources,
  getStationarySourceById,
  getNewStationarySource,
  getStationarySourceByIdOrNew,
  createStationarySource,
  updateStationarySource,
  saveStationarySource,
};
