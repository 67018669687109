/** LookupEnums (LE) must align with their associated Lookups. The associated Lookups must be marked as immutable.
 * Name the enum for the LookupGroup's Code. Name the keys to align with the Lookup's Code. The values should align with the Lookup's Murn.
 */
export namespace LE {
  export enum ConstructionActivityType {
    Construction = "murn:aqms2:lookup/ConstructionActivityType/Construction",
    TemporaryCommercial = "murn:aqms2:lookup/ConstructionActivityType/TemporaryCommercial",
  }

  export enum Country {
    CAN = "murn:aqms2:lookup/Country/CAN",
    USA = "murn:aqms2:lookup/Country/USA",
  }

  export enum State {
    AK = "murn:aqms2:lookup/State/AK",
    AL = "murn:aqms2:lookup/State/AL",
    AR = "murn:aqms2:lookup/State/AR",
    AS = "murn:aqms2:lookup/State/AS",
    AZ = "murn:aqms2:lookup/State/AZ",
    CA = "murn:aqms2:lookup/State/CA",
    CO = "murn:aqms2:lookup/State/CO",
    CT = "murn:aqms2:lookup/State/CT",
    DC = "murn:aqms2:lookup/State/DC",
    DE = "murn:aqms2:lookup/State/DE",
    FL = "murn:aqms2:lookup/State/FL",
    GA = "murn:aqms2:lookup/State/GA",
    GU = "murn:aqms2:lookup/State/GU",
    HI = "murn:aqms2:lookup/State/HI",
    IA = "murn:aqms2:lookup/State/IA",
    ID = "murn:aqms2:lookup/State/ID",
    IL = "murn:aqms2:lookup/State/IL",
    IN = "murn:aqms2:lookup/State/IN",
    KS = "murn:aqms2:lookup/State/KS",
    KY = "murn:aqms2:lookup/State/KY",
    LA = "murn:aqms2:lookup/State/LA",
    MA = "murn:aqms2:lookup/State/MA",
    MD = "murn:aqms2:lookup/State/MD",
    ME = "murn:aqms2:lookup/State/ME",
    MI = "murn:aqms2:lookup/State/MI",
    MN = "murn:aqms2:lookup/State/MN",
    MO = "murn:aqms2:lookup/State/MO",
    MP = "murn:aqms2:lookup/State/MP",
    MS = "murn:aqms2:lookup/State/MS",
    MT = "murn:aqms2:lookup/State/MT",
    NC = "murn:aqms2:lookup/State/NC",
    ND = "murn:aqms2:lookup/State/ND",
    NE = "murn:aqms2:lookup/State/NE",
    NH = "murn:aqms2:lookup/State/NH",
    NJ = "murn:aqms2:lookup/State/NJ",
    NM = "murn:aqms2:lookup/State/NM",
    NV = "murn:aqms2:lookup/State/NV",
    NY = "murn:aqms2:lookup/State/NY",
    OH = "murn:aqms2:lookup/State/OH",
    OK = "murn:aqms2:lookup/State/OK",
    OR = "murn:aqms2:lookup/State/OR",
    PA = "murn:aqms2:lookup/State/PA",
    PR = "murn:aqms2:lookup/State/PR",
    RI = "murn:aqms2:lookup/State/RI",
    SC = "murn:aqms2:lookup/State/SC",
    SD = "murn:aqms2:lookup/State/SD",
    TN = "murn:aqms2:lookup/State/TN",
    TX = "murn:aqms2:lookup/State/TX",
    UT = "murn:aqms2:lookup/State/UT",
    VT = "murn:aqms2:lookup/State/VT",
    VA = "murn:aqms2:lookup/State/VA",
    VI = "murn:aqms2:lookup/State/VI",
    WA = "murn:aqms2:lookup/State/WA",
    WI = "murn:aqms2:lookup/State/WI",
    WV = "murn:aqms2:lookup/State/WV",
    WY = "murn:aqms2:lookup/State/WY",
    AB = "murn:aqms2:lookup/State/AB",
    BC = "murn:aqms2:lookup/State/BC",
    MB = "murn:aqms2:lookup/State/MB",
    NB = "murn:aqms2:lookup/State/NB",
    NL = "murn:aqms2:lookup/State/NL",
    NS = "murn:aqms2:lookup/State/NS",
    ON = "murn:aqms2:lookup/State/ON",
    PE = "murn:aqms2:lookup/State/PE",
    QC = "murn:aqms2:lookup/State/QC",
    SK = "murn:aqms2:lookup/State/SK",
  }
}
