import type { Paging, UseApiConfig } from "@modernary/mui/utils/api";
import type { AxiosResponse } from "axios";

/** Beware - This method mutates the provided `response` object!
 * Runs the `responseAdapter` asynchronously against every item in the response's `_items`
 * @returns `response.data`
 */
export async function adaptPagedResponse<T>(
  response: AxiosResponse<Paging<T>, any>,
  responseAdapter: Required<UseApiConfig<T, T>>["responseAdapter"],
  transformer = (item: T) => ({ ...response, data: item }),
): Promise<Paging<T>> {
  return new Promise(async (resolve) => {
    await Promise.all(response.data._items.map((item) => responseAdapter(transformer(item))));
    resolve(response.data);
  });
}
