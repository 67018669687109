<template>
  <div class="h-full overflow-auto bg-neutral-100">
    <div class="">
      <!-- content -->
      <div class="mx-auto mt-10 grid grid-cols-1 gap-10 xl:grid-cols-2">
        <!-- ConstructionProjects -->
        <div class="3xl:pl-8">
          <div class="mx-0 sm:mx-5 md:mx-8 xl:mr-0 3xl:mx-auto 3xl:max-w-5xl">
            <div class="mb-4 bg-white shadow sm:mb-5 sm:rounded-lg md:mb-8 xl:mx-auto xl:max-w-5xl">
              <div class="border-b border-neutral-200 px-4 py-5 sm:px-6">
                <h3 class="text-lg font-medium leading-6 text-neutral-900">My Construction Projects</h3>
              </div>
              <div
                v-if="isGetConstructionProjectsSuccess && constructionProjects?._items?.length === 0"
                class="bg-neutral-50 p-10 sm:rounded-b-lg"
              >
                <NoneFoundFlag />
              </div>
              <div v-if="isGetConstructionProjectsPending">
                <div class="flex animate-pulse space-x-4">
                  <div class="flex flex-1 items-center px-4 py-4 sm:px-6">
                    <div class="flex min-w-0 flex-1 items-center">
                      <div class="flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <div class="pr-8 text-sm font-medium text-neutral-800 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-300"></div>
                          </div>
                          <div class="mt-2 pr-8 text-sm text-neutral-500 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-200"></div>
                          </div>
                        </div>
                        <div class="hidden justify-center md:flex md:flex-col">
                          <div class="pr-8 text-sm text-neutral-900 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-300"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="h-6 w-6 rounded bg-neutral-200"></div>
                    </div>
                  </div>
                </div>
              </div>
              <ul role="list" class="divide-y divide-neutral-200">
                <li v-for="cp in constructionProjects?._items" :key="cp.id" class="">
                  <router-link
                    :to="{ name: 'ConstructionProjectView', params: { id: cp.id } }"
                    class="group block hover:bg-neutral-100"
                  >
                    <div class="flex items-center px-4 py-4 sm:px-6">
                      <div class="flex min-w-0 flex-1 items-center">
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p class="truncate text-sm font-medium text-neutral-800">
                              {{ cp.name + " - " + cp.number }}
                            </p>
                            <!--<p class="mt-2 flex items-center text-sm text-neutral-500">
                              <span class="truncate">{{ cp.locationDescription }}</span>
                            </p>-->
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          class="z-0 h-6 w-6 text-neutral-300 transition-transform ease-in group-hover:translate-x-1 group-hover:text-neutral-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Stationary Sources -->
        <div class="3xl:pr-8">
          <div class="mx-0 sm:mx-5 md:mx-8 xl:ml-0 3xl:mx-auto 3xl:max-w-5xl">
            <div class="mb-4 bg-white shadow sm:mb-5 sm:rounded-lg md:mb-8 xl:mx-auto xl:max-w-5xl">
              <div class="border-b border-neutral-200 px-4 py-5 sm:px-6">
                <h3 class="text-lg font-medium leading-6 text-neutral-900">My Stationary Sources</h3>
              </div>
              <div
                v-if="isGetStationarySourcesSuccess && stationarySources?._items?.length === 0"
                class="bg-neutral-50 p-10 sm:rounded-b-lg"
              >
                <NoneFoundFlag />
              </div>
              <div v-if="isGetStationarySourcesPending">
                <div class="flex animate-pulse space-x-4">
                  <div class="flex flex-1 items-center px-4 py-4 sm:px-6">
                    <div class="flex min-w-0 flex-1 items-center">
                      <div class="flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <div class="pr-8 text-sm font-medium text-neutral-800 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-300"></div>
                          </div>
                          <div class="mt-2 pr-8 text-sm text-neutral-500 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-200"></div>
                          </div>
                        </div>
                        <div class="hidden justify-center md:flex md:flex-col">
                          <div class="pr-8 text-sm text-neutral-900 lg:pr-16">
                            <div class="h-2 rounded bg-neutral-300"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="h-6 w-6 rounded bg-neutral-200"></div>
                    </div>
                  </div>
                </div>
              </div>
              <ul role="list" class="divide-y divide-neutral-200">
                <li v-for="ss in stationarySources?._items" :key="ss.id" class="">
                  <router-link
                    :to="{ name: 'StationarySourceView', params: { id: ss.id } }"
                    class="group block hover:bg-neutral-100"
                  >
                    <div class="flex items-center px-4 py-4 sm:px-6">
                      <div class="flex min-w-0 flex-1 items-center">
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p class="truncate text-sm font-medium text-neutral-800">
                              {{ ss.name + " - " + ss.number }}
                            </p>
                            <!--<p class="mt-2 flex items-center text-sm text-neutral-500">
                              <span class="truncate">{{ ss.classMurnValue }}</span>
                            </p>-->
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          class="z-0 h-6 w-6 text-neutral-300 transition-transform ease-in group-hover:translate-x-1 group-hover:text-neutral-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="xl:col-span-2">
          <div class="mx-0 sm:mx-5 md:mx-8 xl:ml-0 3xl:mx-auto 3xl:max-w-5xl">
            <div class="mb-4 mt-4 bg-white shadow sm:my-5 sm:rounded-lg md:my-8 xl:mx-auto xl:max-w-2xl">
              <div class="mx-auto max-w-xl">
                <div class="px-4 pb-7 pt-6">
                  <div class="text-center">
                    <h2 class="text-lg font-medium text-neutral-900">Questions?</h2>
                    <p class="mt-1 text-sm text-neutral-500">
                      <span class="text-neutral-700">Tanuja Puvvula</span><br />
                      <span class="leading-loose">
                        <EnvelopeIcon class="mr-2 inline-block h-4 w-4" /><a href="mailto:Tanuja@clarkcountynv.gov"
                          >Tanuja@clarkcountynv.gov</a
                        >
                      </span>
                      <br />
                      <PhoneIcon class="mr-2 inline-block h-4 w-4" />
                      <a href="tel:+1-775-687-9467">+1 (702) 273-8428</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
      <MdrnAttribution />
    </footer>
  </div>
</template>

<script lang="ts">
import { InitialBadge } from "@modernary/mui/components/badge";
import { BaseButton } from "@modernary/mui/components/button";
import { MdrnAttribution, NoneFoundFlag, useAppSettingsStore } from "@modernary/mui/components/common";
import { LoginButton, RegisterButton, useUser } from "@modernary/mui/utils/authentication";
import { computed, defineComponent, watch } from "vue";
import { FaceSmileIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/vue/20/solid";
import { useApi } from "@modernary/mui/utils/api";
import { getConstructionProjects, getStationarySources } from "@/services/endpoints";
import { adaptPagedResponse } from "@/helpers/adaptPagedResponse";
import stationarySourceService from "@/views/stationarySource/stationarySourceService";

// Home content for unauth'd users
export default defineComponent({
  name: "HomeExternal",
  setup() {
    const { userRef } = useUser();
    const appSettingsStore = useAppSettingsStore();

    const isAuthenticated = computed(() => userRef.value.isAuthenticated);

    const {
      dataRef: constructionProjects,
      exec: execGetConstructionProjects,
      isStatusPending: isGetConstructionProjectsPending,
      isStatusSuccess: isGetConstructionProjectsSuccess,
    } = useApi(getConstructionProjects, {
      responseAdapter: (response) => {
        return response.data;
      },
    });

    const {
      dataRef: stationarySources,
      exec: execGetStationarySources,
      isStatusPending: isGetStationarySourcesPending,
      isStatusSuccess: isGetStationarySourcesSuccess,
    } = useApi(getStationarySources, {
      responseAdapter: async (response) => {
        return await adaptPagedResponse(response, stationarySourceService.responseAdapter);
      },
    });

    watch(
      isAuthenticated,
      (newValue) => {
        if (newValue) {
          execGetStationarySources();
          execGetConstructionProjects();
        }
      },
      { immediate: true }
    );

    return {
      userRef,
      BaseButton,
      appSettingsStore,
      isGetConstructionProjectsPending,
      isGetConstructionProjectsSuccess,
      constructionProjects,
      isGetStationarySourcesPending,
      isGetStationarySourcesSuccess,
      stationarySources,
    };
  },
  components: {
    BaseButton,
    InitialBadge,
    LoginButton,
    RegisterButton,
    PlusIcon,
    ChevronRightIcon,
    MdrnAttribution,
    FaceSmileIcon,
    NoneFoundFlag,
    PhoneIcon,
    EnvelopeIcon,
  },
});
</script>
