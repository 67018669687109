import constructionProjectService from "@/views/constructionProject/constructionProjectService";
import { apiService, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiHosts } from "@/api/constants/apiHosts";

import { ConstructionProject, ConstructionProjectNew, ConstructionProjectQuery } from "./constructionProject";
import type { AxiosResponse } from "axios";
import { LE } from "@/constants/enums";

const URL = "construction-projects";
const ENTITY_NAME = "Construction Project";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_AQMS_API_BASE_URL,
};
const api = apiService<ConstructionProject>(apiHosts.AQMS, axiosParams);

const getConstructionProjects = (params: ConstructionProjectQuery, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getConstructionProjectById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewConstructionProject = async (ids: ConstructionProjectIds) => {
  const defaultApp = {
    data: {
      ...constructionProjectService.getInitialData(),
      ...ids,
      activityTypeMurn: LE.ConstructionActivityType.Construction,
    },
  } as AxiosResponse<ConstructionProjectNew, any>;

  return defaultApp;
};

type ConstructionProjectIds = {
  id?: string | number;
};
const getConstructionProjectByIdOrNew = (ids: ConstructionProjectIds, config: PagingApiAndNotifierConfig = {}) => {
  if (!ids.id) {
    return getNewConstructionProject(ids);
  } else {
    return getConstructionProjectById(ids.id, config) as Promise<AxiosResponse<ConstructionProjectNew, any>>;
  }
};

const createConstructionProject = (
  constructionProject: ConstructionProject,
  config: PagingApiAndNotifierConfig = {},
) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, constructionProject, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateConstructionProject = (
  constructionProject: ConstructionProject,
  config: PagingApiAndNotifierConfig = {},
) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + constructionProject.id, constructionProject, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {ConstructionProject} constructionProject This cannot be a 'ref' to a constructionProject
 */
const saveConstructionProject = (constructionProject: ConstructionProject, config: PagingApiAndNotifierConfig = {}) => {
  if (constructionProject.id) {
    return updateConstructionProject(constructionProject, config);
  } else {
    return createConstructionProject(constructionProject, config);
  }
};

const deleteConstructionProjectById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteConstructionProjectById,
  getConstructionProjects,
  getConstructionProjectById,
  getNewConstructionProject,
  getConstructionProjectByIdOrNew,
  createConstructionProject,
  updateConstructionProject,
  saveConstructionProject,
};
