import {
  murnAddressFromFormkit,
  murnAddressToFormkit,
  plainMurnAddressFromFormkit,
  plainMurnAddressToFormkit,
} from "@modernary/mui/components/fk";

import { StationarySource, StationarySourceNew } from "@/views/stationarySource/stationarySource";
import { getLookupDescription } from "@modernary/mui/components/lookup";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: StationarySource) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as StationarySource; //TODO: ts
  return addAuthRequestFields(result);
}

async function responseAdapter(response: { data: StationarySource }) {
  if (response.data.stateMurn) {
    let state = await getLookupDescription(response.data.stateMurn);
    response.data.stateMurnValue = state;
  }
  if (response.data.countryMurn) {
    let country = await getLookupDescription(response.data.countryMurn);
    response.data.countryMurnValue = country;
  }
  addAuthRequestFields(response.data);

  return response.data;
}

function requestAdapter(data: StationarySource) {
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit<T extends StationarySource | StationarySourceNew>(data: T) {
  plainMurnAddressToFormkit(data);
  //TODO: Mask Bug parcel number only set to be undefined to fix formkit bug. Once formkit resolves bug remove
  if (!data.responsibleOfficialPhone) {
    data.responsibleOfficialPhone = undefined;
  }
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: StationarySource) {
  plainMurnAddressFromFormkit(data as StationarySource);
  //TODO: Mask Bug remove this once the if above is removed.
  if (data.responsibleOfficialPhone === undefined) {
    data.responsibleOfficialPhone = null;
  }
}
