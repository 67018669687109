import { EntityAudit } from "@modernary/mui/components/audit";
import { RouterView, type RouterOptions } from "vue-router";

// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  asbestosFacilities: "AsbestosFacilities",
  asbestosFacilityList: "AsbestosFacilityList",
  asbestosFacilityNew: "AsbestosFacilityNew",
  asbestosFacilityBase: "AsbestosFacilityBase",
  asbestosFacilityView: "AsbestosFacilityView",
  asbestosFacilityEdit: "AsbestosFacilityEdit",
  asbestosFacilityAudit: "AsbestosFacilityAudit",
};

export { names as asbestosFacilityRouteNames };

const asbestosFacilityRoutes: RouterOptions["routes"] = [
  {
    path: "/asbestosFacility",
    name: names.asbestosFacilities,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.asbestosFacilityList },
    children: [
      {
        path: "list",
        name: names.asbestosFacilityList,
        meta: { title: "Asbestos Facilities" },
        component: () => import("@/views/asbestosFacility/AsbestosFacilityList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/asbestosFacility/AsbestosFacilityBase.vue"),
        redirect: { name: names.asbestosFacilityNew },
        props: true,
        children: [
          {
            path: "",
            name: names.asbestosFacilityNew,
            meta: { title: "New Asbestos Facility", isNew: true },
            component: () => import("@/views/asbestosFacility/AsbestosFacilityEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.asbestosFacilityBase,
        component: () => import("@/views/asbestosFacility/AsbestosFacilityBase.vue"),
        redirect: { name: names.asbestosFacilityView },
        children: [
          {
            path: "view",
            name: names.asbestosFacilityView,
            meta: { title: "Asbestos Facility View" },
            component: () => import("@/views/asbestosFacility/AsbestosFacilityView.vue"),
          },
          {
            path: "edit",
            name: names.asbestosFacilityEdit,
            meta: { title: "Asbestos Facility Edit", isForm: true },
            component: () => import("@/views/asbestosFacility/AsbestosFacilityEdit.vue"),
          },
          {
            path: "activity",
            name: names.asbestosFacilityAudit,
            meta: { title: "Asbestos Facility Audit Activity" },
            component: {
              render: () => <EntityAudit entityType="AsbestosFacility" />,
            },
          },
        ],
      },
    ],
  },
];
export default asbestosFacilityRoutes;
