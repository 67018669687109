import { EntityAudit } from "@modernary/mui/components/audit";
import { RouterView, type RouterOptions } from "vue-router";

// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  vacantLandParcels: "VacantLandParcels",
  vacantLandParcelList: "VacantLandParcelList",
  vacantLandParcelNew: "VacantLandParcelNew",
  vacantLandParcelBase: "VacantLandParcelBase",
  vacantLandParcelView: "VacantLandParcelView",
  vacantLandParcelEdit: "VacantLandParcelEdit",
  vacantLandParcelAudit: "VacantLandParcelAudit",
};

export { names as vacantLandParcelRouteNames };

const vacantLandParcelRoutes: RouterOptions["routes"] = [
  {
    path: "/vacantLandParcel",
    name: names.vacantLandParcels,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.vacantLandParcelList },
    children: [
      {
        path: "list",
        name: names.vacantLandParcelList,
        meta: { title: "Vacant Land Parcels" },
        component: () => import("@/views/vacantLandParcel/VacantLandParcelList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/vacantLandParcel/VacantLandParcelBase.vue"),
        redirect: { name: names.vacantLandParcelNew },
        props: true,
        children: [
          {
            path: "",
            name: names.vacantLandParcelNew,
            meta: { title: "New Vacant Land Parcel", isNew: true },
            component: () => import("@/views/vacantLandParcel/VacantLandParcelEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.vacantLandParcelBase,
        component: () => import("@/views/vacantLandParcel/VacantLandParcelBase.vue"),
        redirect: { name: names.vacantLandParcelView },
        children: [
          {
            path: "view",
            name: names.vacantLandParcelView,
            meta: { title: "Vacant Land Parcel View" },
            component: () => import("@/views/vacantLandParcel/VacantLandParcelView.vue"),
          },
          {
            path: "edit",
            name: names.vacantLandParcelEdit,
            meta: { title: "Vacant Land Parcel Edit", isForm: true },
            component: () => import("@/views/vacantLandParcel/VacantLandParcelEdit.vue"),
          },
          {
            path: "activity",
            name: names.vacantLandParcelAudit,
            meta: { title: "Vacant Land Parcel Audit Activity" },
            component: {
              render: () => <EntityAudit entityType="VacantLandParcel" />,
            },
          },
        ],
      },
    ],
  },
];
export default vacantLandParcelRoutes;
