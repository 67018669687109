import { murnAddressFromFormkit, murnAddressToFormkit } from "@modernary/mui/components/fk";

import { VacantLandParcel, VacantLandParcelNew } from "@/views/vacantLandParcel/vacantLandParcel";
import { getLookupDescription } from "@modernary/mui/components/lookup";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: VacantLandParcel) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as VacantLandParcel; //TODO: ts
  return addAuthRequestFields(result);
}
async function responseAdapter(response: { data: VacantLandParcel | VacantLandParcelNew }) {
  if (response.data.mailStateMurn) {
    let state = await getLookupDescription(response.data.mailStateMurn);
    response.data.mailStateMurnValue = state;
  }
  if (response.data.mailCountryMurn) {
    let country = await getLookupDescription(response.data.mailCountryMurn);
    response.data.mailCountryMurnValue = country;
  }
  addAuthRequestFields(response.data as VacantLandParcel);
  //TODO: TS
  return response.data as VacantLandParcel;
}
function requestAdapter(data: VacantLandParcel) {
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit<T extends VacantLandParcel | VacantLandParcelNew>(data: T) {
  murnAddressToFormkit(data, "mail");
  //TODO: Mask Bug parcel number only set to be undefined to fix formkit bug. Once formkit resolves bug remove
  if (!data.number) {
    data.number = undefined;
  }
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: VacantLandParcel) {
  murnAddressFromFormkit(data as VacantLandParcel, "mail");
  //TODO: Mask Bug remove this once the if above is removed.
  if (data.number === undefined) {
    data.number = null;
  }
}
