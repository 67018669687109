import { EntityAudit } from "@modernary/mui/components/audit";
import { RouterView, type RouterOptions } from "vue-router";

// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  temps: "Temps",
  tempList: "TempList",
  tempBase: "TempBase",
  temp001: "001",
  temp002: "002",
  temp003: "003",
  temp004: "004",
  temp005: "005",
  temp006: "006",
  temp007: "007",
  temp008: "008",
  temp009: "009",
  temp010: "010",
  temp011: "011",
  temp012: "012",
  temp013: "013",
  temp014: "014",
  temp015: "015",
  temp016: "016",
  temp017: "017",
  temp018: "018",
  temp019: "019",
  temp020: "020",
  temp021: "021",
  temp022: "022",
  temp023: "023",
  temp024: "024",
  temp025: "025",
  temp026: "026",
  temp027: "027",
  temp028: "028",
  temp029: "029",
  temp030: "030",
  temp031: "031",
  temp032: "032",
  temp033: "033",
  temp034: "034",
  temp035: "035",
  temp036: "036",
  temp037: "037",
  temp038: "038",
  temp039: "039",
  temp040: "040",
  temp041: "041",
  temp042: "042",
  temp043: "043",
  temp044: "044",
  temp045: "045",
  temp046: "046",
  temp047: "047",
  temp048: "048",
  temp049: "049",
  temp050: "050",
  temp051: "051",
  temp052: "052",
  temp053: "053",
  temp054: "054",
  temp055: "055",
  temp056: "056",
  temp057: "057",
  temp058: "058",
  temp059: "059",
  temp060: "060",
  temp061: "061",
  temp062: "062",
  temp063: "063",
  temp064: "064",
  temp065: "065",
  temp066: "066",
  temp067: "067",
  temp068: "068",
  temp069: "069",
};

export { names as tempRouteNames };

const tempRoutes: RouterOptions["routes"] = [
  {
    path: "/temp",
    name: names.temps,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.tempList },
    children: [
      {
        path: "list",
        name: names.tempList,
        meta: { title: "Forms" },
        component: () => import("@/views/temp/TempList.vue"),
      },
      {
        path: "base",
        component: () => import("@/views/temp/TempBase.vue"),
        name: names.tempBase,
        props: true,
        children: [
          {
            path: "001",
            name: names.temp001,
            meta: { title: "001", isNew: true },
            component: () => import("@/views/temp/forms/001/TitleVEdit.vue"),
          },
          {
            path: "002",
            name: names.temp002,
            meta: { title: "002", isNew: true },
            component: () => import("@/views/temp/forms/002/AuthorityToConstructEdit.vue"),
          },
          {
            path: "003",
            name: names.temp003,
            meta: { title: "003", isNew: true },
            component: () => import("@/views/temp/forms/003/MinorSourcePermitEdit.vue"),
          },
          {
            path: "004",
            name: names.temp004,
            meta: { title: "004", isNew: true },
            component: () => import("@/views/temp/forms/004/StationarySourcePermitTransferEdit.vue"),
          },
          {
            path: "005",
            name: names.temp005,
            meta: { title: "005", isNew: true },
            component: () => import("@/views/temp/forms/005/PortalSourcePermitMoveEdit.vue"),
          },
          {
            path: "006",
            name: names.temp006,
            meta: { title: "006", isNew: true },
            component: () => import("@/views/temp/forms/006/PetitionForExemptionEdit.vue"),
          },
          {
            path: "007",
            name: names.temp007,
            meta: { title: "007", isNew: true },
            component: () => import("@/views/temp/forms/007/DeclarationOfResponsibleOfficialEdit.vue"),
          },
          {
            path: "008",
            name: names.temp008,
            meta: { title: "008", isNew: true },
            component: () => import("@/views/temp/forms/008/NonroadEngineDeclarationEdit.vue"),
          },
          {
            path: "009",
            name: names.temp009,
            meta: { title: "009", isNew: true },
            component: () => import("@/views/temp/forms/009/PriorNotificationEdit.vue"),
          },
          {
            path: "010",
            name: names.temp010,
            meta: { title: "010", isNew: true },
            component: () => import("@/views/temp/forms/010/ControlDeviceWorksheetEdit.vue"),
          },
          {
            path: "011",
            name: names.temp011,
            meta: { title: "011", isNew: true },
            component: () => import("@/views/temp/forms/011/CycloneWorksheetEdit.vue"),
          },
          {
            path: "012",
            name: names.temp012,
            meta: { title: "012", isNew: true },
            component: () => import("@/views/temp/forms/012/FlareWorksheetEdit.vue"),
          },
          {
            path: "013",
            name: names.temp013,
            meta: { title: "013", isNew: true },
            component: () => import("@/views/temp/forms/013/FabricFilterBaghouseEdit.vue"),
          },
          {
            path: "014",
            name: names.temp014,
            meta: { title: "014", isNew: true },
            component: () => import("@/views/temp/forms/014/ScrubberWorksheetEdit.vue"),
          },
          {
            path: "015",
            name: names.temp015,
            meta: { title: "015", isNew: true },
            component: () => import("@/views/temp/forms/015/AsphaltPlantWorksheetEdit.vue"),
          },
          {
            path: "016",
            name: names.temp016,
            meta: { title: "016", isNew: true },
            component: () => import("@/views/temp/forms/016/BoilerWorksheetEdit.vue"),
          },
          {
            path: "017",
            name: names.temp017,
            meta: { title: "017", isNew: true },
            component: () => import("@/views/temp/forms/017/CannabisWorksheetEdit.vue"),
          },
          {
            path: "018",
            name: names.temp018,
            meta: { title: "018", isNew: true },
            component: () => import("@/views/temp/forms/018/CoolingTowerWorksheetEdit.vue"),
          },
          {
            path: "019",
            name: names.temp019,
            meta: { title: "019", isNew: true },
            component: () => import("@/views/temp/forms/019/ConcretePlantWorksheetEdit.vue"),
          },
          {
            path: "020",
            name: names.temp020,
            meta: { title: "020", isNew: true },
            component: () => import("@/views/temp/forms/020/DrillingBlastingEdit.vue"),
          },
          {
            path: "021",
            name: names.temp021,
            meta: { title: "021", isNew: true },
            component: () => import("@/views/temp/forms/021/PerchloroethyleneDryCleaningWorksheetEdit.vue"),
          },
          {
            path: "022",
            name: names.temp022,
            meta: { title: "022", isNew: true },
            component: () => import("@/views/temp/forms/022/PetroleumDryCleaningWorksheetEdit.vue"),
          },
          {
            path: "023",
            name: names.temp023,
            meta: { title: "023", isNew: true },
            component: () => import("@/views/temp/forms/023/DryerHeaterOvenWorksheetEdit.vue"),
          },
          {
            path: "024",
            name: names.temp024,
            meta: { title: "024", isNew: true },
            component: () => import("@/views/temp/forms/024/InternalCombustionEngineWorksheetEdit.vue"),
          },
          {
            path: "025",
            name: names.temp025,
            meta: { title: "025", isNew: true },
            component: () => import("@/views/temp/forms/025/LandscapeRockOperationsWorksheetEdit.vue"),
          },
          {
            path: "026",
            name: names.temp026,
            meta: { title: "026", isNew: true },
            component: () => import("@/views/temp/forms/026/PetroleumStorageTankWorksheetEdit.vue"),
          },
          {
            path: "027",
            name: names.temp027,
            meta: { title: "027", isNew: true },
            component: () => import("@/views/temp/forms/027/PrintingWorksheetEdit.vue"),
          },
          {
            path: "028",
            name: names.temp028,
            meta: { title: "028", isNew: true },
            component: () => import("@/views/temp/forms/028/PrintingOperationsWorksheetEdit.vue"),
          },
          {
            path: "029",
            name: names.temp029,
            meta: { title: "029", isNew: true },
            component: () => import("@/views/temp/forms/029/RemediationWorksheetEdit.vue"),
          },
          {
            path: "030",
            name: names.temp030,
            meta: { title: "030", isNew: true },
            component: () => import("@/views/temp/forms/030/StoneProcessingOperationsWorksheetEdit.vue"),
          },
          {
            path: "031",
            name: names.temp031,
            meta: { title: "031", isNew: true },
            component: () => import("@/views/temp/forms/031/SurfaceCoatingWorksheetEdit.vue"),
          },
          {
            path: "032",
            name: names.temp032,
            meta: { title: "032", isNew: true },
            component: () => import("@/views/temp/forms/032/SurfaceCoatingOperationsWorksheetEdit.vue"),
          },
          {
            path: "033",
            name: names.temp033,
            meta: { title: "033", isNew: true },
            component: () => import("@/views/temp/forms/033/WoodworkingAncillaryWorksheetEdit.vue"),
          },
          {
            path: "034",
            name: names.temp034,
            meta: { title: "034", isNew: true },
            component: () => import("@/views/temp/forms/034/Edit.vue"),
          },
          {
            path: "035",
            name: names.temp035,
            meta: { title: "035", isNew: true },
            component: () => import("@/views/temp/forms/035/Edit.vue"),
          },
          {
            path: "036",
            name: names.temp036,
            meta: { title: "036", isNew: true },
            component: () => import("@/views/temp/forms/036/AirQualityRegistrationEdit.vue"),
          },
          {
            path: "037",
            name: names.temp037,
            meta: { title: "037", isNew: true },
            component: () => import("@/views/temp/forms/037/NotificationFormAQRs101Edit.vue"),
          },
          {
            path: "038",
            name: names.temp038,
            meta: { title: "038", isNew: true },
            component: () => import("@/views/temp/forms/038/CertificateOfExemptionApplicationEdit.vue"),
          },
          {
            path: "039",
            name: names.temp039,
            meta: { title: "039", isNew: true },
            component: () => import("@/views/temp/forms/039/AppealOfHearingOfficersOrderEdit.vue"),
          },
          {
            path: "040",
            name: names.temp040,
            meta: { title: "040", isNew: true },
            component: () => import("@/views/temp/forms/040/HearingRequestFormEdit.vue"),
          },
          {
            path: "041",
            name: names.temp041,
            meta: { title: "041", isNew: true },
            component: () => import("@/views/temp/forms/041/NESHAPNotificationOfAsbestosAbatementFormEdit.vue"),
          },
          {
            path: "042",
            name: names.temp042,
            meta: { title: "042", isNew: true },
            component: () => import("@/views/temp/forms/042/DemolitionNotificationFormEdit.vue"),
          },
          {
            path: "043",
            name: names.temp043,
            meta: { title: "043", isNew: true },
            component: () => import("@/views/temp/forms/043/DryCleanerEmissionsReportEdit.vue"),
          },
          {
            path: "044",
            name: names.temp044,
            meta: { title: "044", isNew: true },
            component: () => import("@/views/temp/forms/044/AnnualEmissionInventoryReportingEdit.vue"),
          },
          {
            path: "045",
            name: names.temp045,
            meta: { title: "045", isNew: true },
            component: () => import("@/views/temp/forms/045/SoilGroundwaterReportEdit.vue"),
          },
          {
            path: "046",
            name: names.temp046,
            meta: { title: "046", isNew: true },
            component: () => import("@/views/temp/forms/046/StoneProcessingReportEdit.vue"),
          },
          {
            path: "047",
            name: names.temp047,
            meta: { title: "047", isNew: true },
            component: () => import("@/views/temp/forms/047/SurfaceCoatingOperationReportEdit.vue"),
          },
          {
            path: "048",
            name: names.temp048,
            meta: { title: "048", isNew: true },
            component: () => import("@/views/temp/forms/048/IndustrialAdhesiveReportingEdit.vue"),
          },
          {
            path: "049",
            name: names.temp049,
            meta: { title: "049", isNew: true },
            component: () => import("@/views/temp/forms/049/GasolineDispensingFacilitiesEmissionsReportEdit.vue"),
          },
          {
            path: "050",
            name: names.temp050,
            meta: { title: "050", isNew: true },
            component: () => import("@/views/temp/forms/050/MetalOrPlasticPartsReportingEdit.vue"),
          },
          {
            path: "051",
            name: names.temp051,
            meta: { title: "051", isNew: true },
            component: () => import("@/views/temp/forms/051/IndustrialCleaningSolventReportingEdit.vue"),
          },
          {
            path: "052",
            name: names.temp052,
            meta: { title: "052", isNew: true },
            component: () => import("@/views/temp/forms/052/MetalSolventDegreaserReportingEdit.vue"),
          },
          {
            path: "053",
            name: names.temp053,
            meta: { title: "053", isNew: true },
            component: () => import("@/views/temp/forms/053/PrintingAndGraphicsReportingEdit.vue"),
          },
          {
            path: "054",
            name: names.temp054,
            meta: { title: "054", isNew: true },
            component: () => import("@/views/temp/forms/054/CutbackAsphaltReportingEdit.vue"),
          },
          {
            path: "055",
            name: names.temp055,
            meta: { title: "055", isNew: true },
            component: () => import("@/views/temp/forms/055/ExcessEmissionsNotificationEdit.vue"),
          },
          {
            path: "056",
            name: names.temp056,
            meta: { title: "056", isNew: true },
            component: () => import("@/views/temp/forms/056/SeventyTwoHourStationarySourceDeviationReportEdit.vue"),
          },
          {
            path: "057",
            name: names.temp057,
            meta: { title: "057", isNew: true },
            component: () => import("@/views/temp/forms/057/Part70AnnualComplianceCertificationEdit.vue"),
          },
          {
            path: "058",
            name: names.temp058,
            meta: { title: "058", isNew: true },
            component: () => import("@/views/temp/forms/058/UniversalComplianceCertificationFormEdit.vue"),
          },
          {
            path: "059",
            name: names.temp059,
            meta: { title: "059", isNew: true },
            component: () => import("@/views/temp/forms/059/Method9VisibleEmissionsEvaluationEdit.vue"),
          },
          {
            path: "060",
            name: names.temp060,
            meta: { title: "060", isNew: true },
            component: () => import("@/views/temp/forms/060/GasolineDispensingTestNotificationEdit.vue"),
          },
          {
            path: "061",
            name: names.temp061,
            meta: { title: "061", isNew: true },
            component: () => import("@/views/temp/forms/061/GasolineDispensingTestResultsEdit.vue"),
          },
          {
            path: "062",
            name: names.temp062,
            meta: { title: "062", isNew: true },
            component: () => import("@/views/temp/forms/062/PressureDecayTestResultsEdit.vue"),
          },
          {
            path: "063",
            name: names.temp063,
            meta: { title: "063", isNew: true },
            component: () => import("@/views/temp/forms/063/DynamicBackPressureTestResultsEdit.vue"),
          },
          {
            path: "064",
            name: names.temp064,
            meta: { title: "064", isNew: true },
            component: () => import("@/views/temp/forms/064/AirToLiquidRatioTestResultsEdit.vue"),
          },
          {
            path: "065",
            name: names.temp065,
            meta: { title: "065", isNew: true },
            component: () => import("@/views/temp/forms/065/LeakRateTestResultEdit.vue"),
          },
          {
            path: "066",
            name: names.temp066,
            meta: { title: "066", isNew: true },
            component: () => import("@/views/temp/forms/066/FlowRateTestResultsEdit.vue"),
          },
          {
            path: "067",
            name: names.temp067,
            meta: { title: "067", isNew: true },
            component: () => import("@/views/temp/forms/067/StaticTorqueTestFormEdit.vue"),
          },
          {
            path: "068",
            name: names.temp068,
            meta: { title: "068", isNew: true },
            component: () => import("@/views/temp/forms/068/VaporReturnLineTestResultsEdit.vue"),
          },
          {
            path: "069",
            name: names.temp069,
            meta: { title: "069", isNew: true },
            component: () => import("@/views/temp/forms/069/OpenBurningApplicationEdit.vue"),
          },
        ],
      },
    ],
  },
];
export default tempRoutes;
