<template>
  <router-view />
  <BaseNotifications />
  <TheCssBreakpointDetector />
</template>

<script setup lang="tsx">
import { useRoute } from "vue-router";
import { BaseNotifications } from "@modernary/mui/components/notifications";
import { TheCssBreakpointDetector, useAppNav } from "@modernary/mui/components/common";

import { routeNames } from "@/routes";

const route = useRoute();
const { setNavigationItems, setApplicationTitleComponent } = useAppNav();

const titleComponent = () => <div>AQMS</div>;
setApplicationTitleComponent(titleComponent);

// Nav items should be set one time during app initilization / config.
setNavigationItems([
  {
    label: "home",
    className: "",
    showLabel: false,
    items: [
      { label: "Home", name: routeNames.home },
      // { label: "Complaints", name: routeNames.complaints },
      // { label: "Contacts", name: routeNames.persons },
    ],
  },
  {
    label: "Stationary Sources",
    className: "xl:break-before-column",
    items: [{ label: "Stationary Sources", name: routeNames.stationarySources }],
  },
  {
    label: "Dust Control",
    className: "lg:break-before-column",
    items: [{ label: "Construction Projects", name: routeNames.constructionProjects }],
  },
  {
    label: "Vacant Lands",
    className: "",
    items: [{ label: "Vacant Land Parcels", name: routeNames.vacantLandParcels }],
  },
  {
    label: "Asbestos",
    className: "",
    items: [{ label: "Asbestos Facilities", name: routeNames.asbestosFacilities }],
  },
]);
</script>
