import { ConstructionProject, ConstructionProjectNew } from "@/views/constructionProject/constructionProject";
import { getLookupDescription, getLookupLongDescriptionWithFallback } from "@modernary/mui/components/lookup";
import { apiService } from "@modernary/mui/utils/api";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: ConstructionProject) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as ConstructionProject; //TODO: ts
  return addAuthRequestFields(result);
}
async function responseAdapter(response: { data: ConstructionProject }) {
  addAuthRequestFields(response.data);

  //Formatting the cross streets (based on rule that only proximity can be null)
  response.data.crossStreetsFormatted = `${response.data.crossStreetFirst}/${response.data.crossStreetSecond}`;
  if (response.data.crossStreetProximityMurn) {
    let proximityValue = await getLookupLongDescriptionWithFallback(response.data.crossStreetProximityMurn);
    response.data.crossStreetsFormatted += `, ${proximityValue}`;
  }

  return response.data;
}
function requestAdapter(data: ConstructionProject) {
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit<T extends ConstructionProject | ConstructionProjectNew>(data: T) {
  //TODO: Mask Bug parcel number only set to be undefined to fix formkit bug. Once formkit resolves bug remove
  if (!data.parcelNumber) {
    data.parcelNumber = undefined;
  }
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: ConstructionProject) {
  //TODO: Mask Bug remove this once the if above is removed.
  if (data.parcelNumber === undefined) {
    data.parcelNumber = null;
  }
}
