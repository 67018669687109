import { EntityAudit } from "@modernary/mui/components/audit";
import { RouterView, type RouterOptions } from "vue-router";

// Troubleshoot your routes using this - https://paths.esm.dev

const names = {
  constructionProjects: "ConstructionProjects",
  constructionProjectList: "ConstructionProjectList",
  constructionProjectNew: "ConstructionProjectNew",
  constructionProjectBase: "ConstructionProjectBase",
  constructionProjectView: "ConstructionProjectView",
  constructionProjectEdit: "ConstructionProjectEdit",
  constructionProjectUsers: "ConstructionProjectUsers",
  constructionProjectUserNew: "ConstructionProjectUserNew",
  constructionProjectAudit: "ConstructionProjectAudit",
};

export { names as constructionProjectRouteNames };

const constructionProjectRoutes: RouterOptions["routes"] = [
  {
    path: "/constructionProject",
    name: names.constructionProjects,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.constructionProjectList },
    children: [
      {
        path: "list",
        name: names.constructionProjectList,
        meta: { title: "Construction Projects" },
        component: () => import("@/views/constructionProject/ConstructionProjectList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/constructionProject/ConstructionProjectBase.vue"),
        redirect: { name: names.constructionProjectNew },
        props: true,
        children: [
          {
            path: "",
            name: names.constructionProjectNew,
            meta: { title: "New Construction Project", isNew: true },
            component: () => import("@/views/constructionProject/ConstructionProjectEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.constructionProjectBase,
        component: () => import("@/views/constructionProject/ConstructionProjectBase.vue"),
        redirect: { name: names.constructionProjectView },
        children: [
          {
            path: "view",
            name: names.constructionProjectView,
            meta: { title: "Construction Project View" },
            component: () => import("@/views/constructionProject/ConstructionProjectView.vue"),
          },
          {
            path: "edit",
            name: names.constructionProjectEdit,
            meta: { title: "Construction Project Edit", isForm: true },
            component: () => import("@/views/constructionProject/ConstructionProjectEdit.vue"),
          },
          {
            path: "users",
            name: names.constructionProjectUsers,
            meta: { title: "Construction Project Users" },
            component: () => import("@/views/constructionProject/ConstructionProjectUsers.vue"),
          },
          {
            path: "users/new",
            name: names.constructionProjectUserNew,
            meta: { title: "Add Construction Project User", isForm: true },
            component: () => import("@/views/constructionProject/ConstructionProjectUser.vue"),
          },
          {
            path: "activity",
            name: names.constructionProjectAudit,
            meta: { title: "Construction Project Audit Activity" },
            component: {
              render: () => <EntityAudit entityType="ConstructionProject" />,
            },
          },
        ],
      },
    ],
  },
];
export default constructionProjectRoutes;
